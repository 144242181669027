.clickable {
    cursor: pointer !important;
}

.FolderTree .TreeNode {
    line-height: 1.75;
}

.folder-wrapper.wide .displayName {
    white-space: break-spaces;
}

.FolderTree {
    user-select: none;
}

.TreeNodeToolBar {
    display: block;
    margin-bottom: 10px;
}

.TreeNode[style*="margin-left: 30px;"] .TreeNodeToolBar {
    margin-left: 17px !important;
}

.TreeNode[style*="margin-left: 60px;"] .TreeNodeToolBar {
    margin-left: -1px !important;
}


/* Colores para el modo oscuro */
html[data-color-mode="dark"] .skeleton-item {
    background-color: #333333;
    opacity: 0.8;
    transition: background-color 0.3s ease-in-out;
}

html[data-color-mode="dark"] .skeleton-item.loaded {
    background-color: #333333;
}
  