.required {
    color: red;
}

h2 {
    font-weight: 400;
}

.demo-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    background-color: #fff;
}

.section-title {
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}

.section-title.basic {
    margin-bottom: 20px;
    margin-top: 20px;
}

/* Colores para el modo oscuro */
html[data-color-mode="dark"] .demo-wrapper,
html[data-color-mode="dark"] .demo-editor,
html[data-color-mode="dark"] .toolbar-class,
html[data-color-mode="dark"] .rdw-option-wrapper,
html[data-color-mode="dark"] .rdw-image-modal,
html[data-color-mode="dark"] .rdw-image-modal-upload-option,
html[data-color-mode="dark"] .rdw-image-modal-btn,
html[data-color-mode="dark"] .rdw-link-modal,
html[data-color-mode="dark"] .rdw-link-modal-btn {
    background-color: #444;
    color: #B6C2CF;
}
