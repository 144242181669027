/* SideNavigation.css */
.navigation-provider {
  display: flex;
}

.side-navigation {
  width: 200px;
  background-color: white;
  box-shadow: 3px 0px 5px #efefef;
  padding: 16px;
  box-sizing: border-box;
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
}

.side-navigation.collapsed {
  width: 50px;
}

.side-navigation .header {
  padding-top: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 16px;
  cursor: pointer;
}

.side-navigation .header > span {
  height: 24px;
  padding-top: 5px;
}

.toggle-button {
  background: none;
  border: none;
  color: #42526e;
  font-size: 24px;
  width: 100%;
  text-align: right;
}

.side-navigation.collapsed .toggle-button {
  display: contents;
}

.navigation-section {
  margin-bottom: 16px;
}

.navigation-heading {
  font-size: 14px;
  font-weight: bold;
  color: #42526e;
  margin-bottom: 8px;
}

.navigation-item {
  display: block;
  padding: 8px 0px;
  padding-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-decoration: none;
  color: #172b4d;
  border-radius: 4px;
  transition: background-color 0.2s;
  font-size: 14px;
  display: flex;
}

.navigation-item.active {
  background-color: rgb(233, 242, 255);
  color: rgb(12, 102, 228);
}

.navigation-item::before {
  margin-right: 4px;
  display: inline-block;
  width: 4px;
  content: "";
}

.navigation-item.active::before {
  background: #0052CC;
  width: 4px;
  border-radius: 0 4px 4px 0;
  content: "";
  inset-block-end: 12px;
  inset-block-start: 12px;
  inset-inline-start: 0px;
  display: inline-block;
  height: 18px;
  margin-right: 4px;
}

.navigation-item:hover {
  background-color: #e9eff5;
}

.collapsible-section {
  margin-bottom: 16px;
}

.collapsible-heading {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(66, 82, 110);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}


/* Colores para el modo oscuro */
html[data-color-mode="dark"] .side-navigation {
  background-color: #333333;
  color: #B6C2CF;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
}

html[data-color-mode="dark"] .side-navigation .header,
html[data-color-mode="dark"] .navigation-heading,
html[data-color-mode="dark"] .navigation-item,
html[data-color-mode="dark"] .collapsible-heading,
html[data-color-mode="dark"] .navigation-item.active::before,
html[data-color-mode="dark"] .side-navigation.collapsed .toggle-button,
html[data-color-mode="dark"] .navigation-item {
  color: #B6C2CF;
}

html[data-color-mode="dark"] .navigation-item.active {
  background-color: #444444;
  color: #B6C2CF;
}

html[data-color-mode="dark"] .navigation-item:hover,
html[data-color-mode="dark"] .collapsible-heading:hover {
  background-color: #555555;
}

html[data-color-mode="dark"] .collapsible-content,
html[data-color-mode="dark"] .side-navigation.collapsed {
  background-color: #333333;
}
