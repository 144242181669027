span.result-skip {
    color: gray;
}

span.result-failure {
    color: red;
}

span.result-success {
    color: green;
}

.demo-preconditions {
    overflow: hidden;
    height: inherit;
}


html[data-color-mode="dark"] .demo-preconditions {
    background-color: #1D2125;
    color: #B6C2CF
}