.icon-hover {
    color: black;
    cursor: pointer;
    padding-right: 5px;
}

.icon-hover:hover {
    color: blue !important; 
}

/* Colores para el modo oscuro */
html[data-color-mode="dark"] .icon-hover {
    color: white;
}

html[data-color-mode="dark"] .icon-hover:hover {
    color: lightblue !important;
}